import React from 'react';

export const modernCEOForm = {
  id: 1192,
  logo: (
    <img
      src="https://img-cdn.inc.com/image/upload/v1674510360/inc-hosted-images/modern_ceo.svg"
      width="253px"
      alt=""
    />
  ),
  description: 'Get the insights you need, from CEO to CEO',
  submitMsg: 'Thank you! Look out for Modern CEO every Monday.'
};

export const pluggedInForm = {
  id: 1195,
  logo: (
    <img
      src="https://assets.fastcompany.com/image/upload/v1679011502/fcweb/fc-newsletter-logo-small-2.svg"
      alt="plugged-in-newsletter-logo"
    />
  ),
  submitMsg: 'Thank you! Look out for Plugged In every Wednesday.'
};

export const workSmarterForm = {
  id: 1314,
  description:
    'Sign up for career, leadership, and productivity advice, curated by Fast Company’s Work Life editors, right in your inbox',
  logo: (
    <img
      src="https://assets.fastcompany.com/image/upload/v1683646800/newsletters/fc-worksmarter-logo.svg"
      alt="work-smarter"
      width="250px"
    />
  ),
  submitMsg: 'Thank you! Look out for your first email'
};
