import React from 'react';
import PropTypes from 'prop-types';

export default function PromoBanner(props) {
  return (
    <div className="scrolling-module__promo">
      <a href={props.click_through_url} aria-label="promo banner link">
        <img
          src={props.image_url.replace('.png', '.webp')}
          alt=""
          width={387}
          height={100}
        />
      </a>
    </div>
  );
}

PromoBanner.propTypes = {
  click_through_url: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired
};
