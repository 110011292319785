import React from 'react';
import PropTypes from 'prop-types';
import MarketoProfileForm from './MarketoProfileForm';

const Profile = ({ profileImg, profileHtml, imgClass, form, profileClass }) => (
  <div className={profileClass}>
    <div className="profile">
      {profileImg && (
        <img
          src={profileImg}
          alt="profile_avator"
          className={`profile_avatar ${imgClass}`}
        />
      )}

      <div dangerouslySetInnerHTML={{ __html: profileHtml }} />
    </div>
    <MarketoProfileForm
      formId={form.id}
      description={form.description}
      logo={form.logo}
      alt={form.alt}
      submitMsg={form.submitMsg}
    />
  </div>
);

Profile.propTypes = {
  profileHtml: PropTypes.string.isRequired,
  profileImg: PropTypes.string.isRequired,
  imgClass: PropTypes.string.isRequired,
  form: PropTypes.shape.isRequired,
  profileClass: PropTypes.string.isRequired
};

export default Profile;
