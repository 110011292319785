import React from 'react';
import Profile from './profile';
import {
  pluggedInForm,
  modernCEOForm,
  workSmarterForm
} from '../newsletters/newsletterForms';

export const PluggedInProfile = () => (
  <div className="featured-feed_sidebar">
    <Profile
      profileHtml="I’m global technology editor <a href='//www.fastcompany.com/user/harry-mccracken' target='_blank'>Harry McCracken</a>. Each week in Plugged In, I unpack the latest developments in tech—from AI breakthroughs to the latest gadgets—and highlight our top stories, right in your inbox."
      profileImg="https://images.fastcompany.net/image/upload/v1679062852/fcweb/zobvf0ysrjc84nta1gfp.png"
      imgClass=""
      form={pluggedInForm}
    />
  </div>
);

export const ModernCEOProfile = () => (
  <div className="featured-feed_sidebar">
    <Profile
      profileHtml="Hello and welcome to Modern CEO! I’m Stephanie Mehta, CEO and chief content officer of Mansueto Ventures. Each week this newsletter explores inclusive approaches to leadership drawn from conversations with executives and entrepreneurs, and from the pages of Inc. and Fast Company. Sign up to get it for yourself every Monday morning."
      profileImg="https://assets.fastcompany.com/image/upload/v1682001649/Modern%20CEO%20Lander/SM-Illo-SectionPage.webp"
      imgClass="modern_ceo_avatar"
      form={modernCEOForm}
    />
  </div>
);

export const WorkSmarterProfile = () => (
  <div className="featured-feed_sidebar">
    <Profile
      profileHtml=""
      profileImg=""
      imgClass=""
      form={workSmarterForm}
      profileClass="work-smarter"
    />
  </div>
);
