import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ClientSideWrapper = ({ showOnlyDesktop, showOnlyMobile, children }) => {
  const [wasMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!wasMounted || !children) {
    return null;
  }

  const desktopQuery = `(max-width: 767px)`;
  const shouldHideDesktop = window.matchMedia(desktopQuery).matches;

  const mobileQuery = `(min-width: 768px)`;
  const shouldHideMobile = window.matchMedia(mobileQuery).matches;

  const determineDisplay = () => {
    if (
      (showOnlyDesktop && !shouldHideDesktop) ||
      (showOnlyMobile && !shouldHideMobile)
    ) {
      return children;
    }
    return null;
  };

  return determineDisplay();
};

ClientSideWrapper.defaultProps = {
  showOnlyDesktop: false,
  showOnlyMobile: false,
  children: null
};

ClientSideWrapper.propTypes = {
  children: PropTypes.node,
  showOnlyDesktop: PropTypes.bool,
  showOnlyMobile: PropTypes.bool
};

export default ClientSideWrapper;
