import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../MarketoForm/MarketoForm';

const MarketoProfileForm = ({ formId, logo, description, submitMsg }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className="marketo_profile_form">
      {logo}
      {submitted ? (
        <p>{submitMsg}</p>
      ) : (
        <React.Fragment>
          <p>{description}</p>
          <MarketoForm
            assetId={formId}
            name="inline-profile-form"
            onSubmitHandler={setSubmitted}
          />
        </React.Fragment>
      )}
      <span>
        <a
          href="https://www.mansueto.com/privacy-policy/"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
      </span>
    </div>
  );
};

MarketoProfileForm.propTypes = {
  formId: PropTypes.number.isRequired,
  logo: PropTypes.element.isRequired,
  description: PropTypes.string.isRequired,
  submitMsg: PropTypes.string.isRequired
};
export default MarketoProfileForm;
