import assign from 'lodash/assign';

export default function formatPostObject(config) {
  const { post, imgSize, allowNoImage = true, allowTextOnly = false } = config;
  let formattedPost = {
    assetSizes: imgSize,
    allowNoImage,
    noLazy: true,
    allowTextOnly
  };

  formattedPost = assign(formattedPost, post);

  return formattedPost;
}
