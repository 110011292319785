import React, { Component, Fragment } from 'react';
import { canUseDOM } from 'exenv';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { imageSizesLookup } from '../../../utils/cloudinary_asset_util';
import formatPostObject from '../../../utils/format_post_object';
import Card from '../card/card';
import Scroll from '../scroll/scroll';
import Social from '../social/social';
import AdContainer from '../ad/AdContainer';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import asyncPostNativeAdActions from '../../../redux/actions/post/async_post_native_ad_actions';
import PianoInline from '../piano/pianoInline';
import {
  ModernCEOProfile,
  PluggedInProfile,
  WorkSmarterProfile
} from '../profile/profileForms';

class FeatureFeed extends Component {
  constructor(props) {
    super(props);
    this.buildActiveSponsoredCardDataObject = this.buildActiveSponsoredCardDataObject.bind(
      this
    );
    this.getFeaturedStory = this.getFeaturedStory.bind(this);
    this.getSponsoredCardDataFromNativePixel = this.getSponsoredCardDataFromNativePixel.bind(
      this
    );
    this.getScrollingModule = this.getScrollingModule.bind(this);
    this.renderSponsoredCard = this.renderSponsoredCard.bind(this);
    this.renderPromoCard = this.renderPromoCard.bind(this);
    this.state = {
      sponsoredCardData: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const nativeAdHomepageTileLoaded = get(
      nextProps,
      'adsLoaded.native_homepagefeature'
    );

    if (nativeAdHomepageTileLoaded) {
      this.getSponsoredCardDataFromNativePixel();
    }

    const newPostNativeAd = get(
      nextProps,
      'postNativeAd.homepageFeaturedTile.posts'
    );

    if (
      get(newPostNativeAd, 'length') !==
        get(this.props, 'postNativeAd.homepageFeaturedTile.posts.length') &&
      get(newPostNativeAd, 'length')
    ) {
      this.setState({
        sponsoredCardData: newPostNativeAd
      });
    }
  }

  getFeaturedStory(post) {
    return formatPostObject({
      props: this.props,
      post,
      imgSize: imageSizesLookup[1],
      allowTextOnly: true
    });
  }

  getSponsoredCardDataFromNativePixel() {
    const initialPageLoad = get(this.props, 'config.initialPageLoad');

    if (!initialPageLoad && canUseDOM) {
      const nativeAdsData = get(window, 'fc_ads.native.homepageFeaturedTile');
      // console.log('nativeAdsData', nativeAdsData);
      if (!nativeAdsData) {
        return;
      }

      // Check if it's native_type === homepageFeaturedTile
      if (get(nativeAdsData, 'native_type') === 'homepageFeaturedTile') {
        if (
          !get(nativeAdsData, 'article_ids') &&
          !get(nativeAdsData, 'campaign_name') &&
          !get(nativeAdsData, 'clickthrough_url')
        ) {
          return;
        }

        this.props.dispatchPostNativeAd(
          nativeAdsData,
          get(this.props, 'config')
        );
      }
    }
  }

  getScrollingModule(scrollingModule) {
    const scrollingModuleTag = get(scrollingModule, 'tag', null);
    const newsStories = get(scrollingModule, 'posts', []).map(post =>
      formatPostObject({
        props: this.props,
        post,
        imgSize: imageSizesLookup.column,
        allowNoImage: true,
        deck: false
      })
    );
    const newsFeed = get(newsStories, 'length')
      ? { stories: newsStories, tag: scrollingModuleTag }
      : { stories: [], tag: scrollingModuleTag };

    return newsFeed;
  }

  buildActiveSponsoredCardDataObject() {
    // console.log('build sponsor card');
    const featuredSponsoredCardType = get(
      this.state.sponsoredCardData,
      '[0].cardType'
    );

    if (!featuredSponsoredCardType) {
      // console.log('no sponsored card found');
      return [
        {
          newsletter: true,
          poster:
            'https://assets.fastcompany.com/image/upload/c_scale,w_289/v1605552418/co-design-logo-neg-2020_2x.webp',
          title: 'Innovation in your inbox.',
          deck: 'Sign up for the daily newsletter.',
          noLazy: true
        }
      ];
    }

    // const parsedCardMetadata = JSON.parse(cardMetadata);
    const featuredSponsoredCardData = {
      Event() {
        const nativeAdsData = get(window, 'fc_ads.native.homepageFeaturedTile');

        return [
          {
            id: '12345',
            topic: 'Events',
            title: get(nativeAdsData, 'caption_text'),
            link: get(nativeAdsData, 'clickthrough_url'),
            featured_image: {
              source: get(nativeAdsData, 'poster')
            },
            external: true,
            currentSite: get(this.props, 'config.site'),
            events: true,
            customEyebrowText: get(nativeAdsData, 'sponsor'),
            buttonText: get(nativeAdsData, 'button_text'),
            assetSizes: imageSizesLookup.bigFirstMobile,
            noLazy: true
          }
        ];
      },
      'Sponsored Story': () => this.state.sponsoredCardData
    };
    // figure out which card type it is, and send relevant metadata
    return featuredSponsoredCardData[featuredSponsoredCardType]();
  }

  renderSponsoredCard() {
    // console.log('render sponsored card');
    const initialPageLoad = get(this.props, 'config.initialPageLoad');

    if (!initialPageLoad && canUseDOM) {
      // console.log('should build sponsor card');
      const item = this.buildActiveSponsoredCardDataObject()[0];

      if (!get(item, 'newsletter')) {
        return (
          <Fragment>
            <Card metadata={item} />
          </Fragment>
        );
      }

      return (
        <div className="card card--default" style={{ display: 'none' }}>
          <Fragment>
            <PianoInline />
          </Fragment>
        </div>
      );
    }

    // server render nothing
    return '';
  }

  renderPromoCard(arrayLength) {
    if (arrayLength === 0 || !arrayLength) {
      return false;
    }
    return (
      <Fragment>
        {this.props.promoterChannelFcWorks.map((obj, index) => (
          <Card
            key={index}
            type="promo"
            config={get(this.props, 'config')}
            metadata={obj}
            last={index === arrayLength}
            supertags={this.props.supertags}
          />
        ))}
      </Fragment>
    );
  }

  render() {
    const {
      featuredFeed,
      scrollingModule,
      social,
      config,
      shouldRenderDesktop,
      promoterChannelFcWorks,
      loading,
      tagFeaturedFeed,
      noAds,
      fcWorksCard
    } = this.props;
    const featured = get(featuredFeed, 'featured', []);
    const topStories = get(featuredFeed, 'top', []);
    const totalTopStories = get(topStories, 'length');
    const totalPromoterStories = get(promoterChannelFcWorks, 'length', 0);
    const hasSocial = !isEmpty(social) && 'row--social';
    const isPluggedInTag = get(tagFeaturedFeed, 'tag.id') === 163674;
    const renderAd = get(this.props, 'featuredFeed.featured').length && !noAds;
    const isModernCEOTag = get(tagFeaturedFeed, 'tag.id') === 164258;
    const isWorkSmarterTag = get(tagFeaturedFeed, 'tag.id') === 85219;

    return (
      <section className={`featured-feed featured-feed--${this.props.page} `}>
        <div className={`row ${hasSocial}`}>
          {hasSocial && <Social {...social} />}
          <div className="featured-feed__stories">
            {featured.map((feature, index) => {
              const featuredStory = this.getFeaturedStory(feature);
              return (
                <Card
                  key={`feature-${index}`}
                  type="featured"
                  metadata={featuredStory}
                  config={config}
                  cardEvent={() => {
                    trackGAEvent({
                      eventCategory: 'Engagement',
                      eventAction: `FeaturedFeed:Pos-${index + 1}`,
                      eventLabel: get(featuredStory, 'link')
                    });
                  }}
                  loading={loading}
                />
              );
            })}
          </div>

          {(() => {
            if (isPluggedInTag) {
              return <PluggedInProfile />;
            }
            if (isModernCEOTag) {
              return <ModernCEOProfile />;
            }
            if (isWorkSmarterTag) {
              return <WorkSmarterProfile />;
            }
            return (
              <Scroll
                config={config}
                metadata={this.getScrollingModule(scrollingModule)}
                promoBanner={this.props.promoBanner}
                className="news-column"
                bottomAd={featured.length > 1}
                shouldRenderDesktop={shouldRenderDesktop}
              />
            );
          })()}
        </div>

        {renderAd && (
          <div className={`${this.props.page}-page__ff-ad-container`}>
            <AdContainer type="break" />
          </div>
        )}

        {totalTopStories >= 2 &&
          !isPluggedInTag &&
          !isWorkSmarterTag && (
            <div className="row">
              {topStories.slice(0, 2).map((post, i, arr) => (
                <Card
                  key={post.id}
                  metadata={formatPostObject({
                    props: this.props,
                    post,
                    imgSize: imageSizesLookup[arr.length + 1],
                    allowTextOnly: true
                  })}
                />
              ))}
              {fcWorksCard && (
                <div style={{ background: '#f4f4f4' }}>
                  <Card
                    key={fcWorksCard.url || fcWorksCard?.articleLink}
                    metadata={{
                      ...fcWorksCard,
                      link: fcWorksCard.url || fcWorksCard?.articleLink,
                      categories: [
                        {
                          name: fcWorksCard.eyebrow,
                          slug: fcWorksCard.url,
                          podcastEmbed: ''
                        }
                      ]
                    }}
                  />
                </div>
              )}
              {this.renderSponsoredCard()}
            </div>
          )}

        {totalPromoterStories > 0 &&
          !isPluggedInTag &&
          !isWorkSmarterTag && (
            <div className="promo-container">
              <div className="promo-container--image-container">
                {' '}
                <img
                  className="promo-container--partnership"
                  src="https://assets.fastcompany.com/image/upload/v1619192201/fc_works_promo.webp"
                  alt=""
                />{' '}
              </div>
              <div className="channel-fc-works-promo">
                {' '}
                {this.renderPromoCard(totalPromoterStories)}{' '}
              </div>
            </div>
          )}

        {totalTopStories >= 4 &&
          !isPluggedInTag &&
          !isWorkSmarterTag && (
            <div className="row">
              {topStories.slice(2, 4).map((post, i, arr) => (
                <Card
                  key={`feature-feed-article-${post.id}`}
                  metadata={formatPostObject({
                    props: this.props,
                    post,
                    imgSize: imageSizesLookup[arr.length],
                    allowTextOnly: true
                  })}
                />
              ))}
            </div>
          )}
      </section>
    );
  }
}

function mapStateToProps(state = {}) {
  return {
    config: state.config,
    postpage: state.postpage,
    postNativeAd: get(state, 'postNativeAd.data', {}),
    adsLoaded: get(state, 'ads.data'),
    supertags: get(state, 'supertags.supertags', {}),
    tagFeaturedFeed: {
      tag: get(state, 'tagFeaturedFeed.tag') || {},
      error: get(state, 'tagFeaturedFeed.error') || false
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchPostNativeAd(nativeAdsData, config) {
      return asyncPostNativeAdActions(dispatch, config, nativeAdsData);
    }
  };
}

FeatureFeed.defaultProps = {
  isPromoting: false,
  social: {},
  promoBanner: {},
  postNativeAd: {},
  shouldRenderDesktop: false,
  adPostion: 1,
  promoterChannelFcWorks: [],
  loading: '',
  supertags: [],
  noAds: false,
  fcWorksCard: undefined
};

FeatureFeed.propTypes = {
  config: PropTypes.shape({}).isRequired,
  featuredFeed: PropTypes.shape({
    source: PropTypes.string,
    featured: PropTypes.arrayOf(PropTypes.shape({})),
    top: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  scrollingModule: PropTypes.shape({}).isRequired,
  promoterChannelFcWorks: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchPostNativeAd: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  postNativeAd: PropTypes.shape({}),
  promoBanner: PropTypes.shape({}),
  isPromoting: PropTypes.bool,
  social: PropTypes.shape({}),
  shouldRenderDesktop: PropTypes.bool,
  adPostion: PropTypes.number,
  loading: PropTypes.string,
  supertags: PropTypes.arrayOf(PropTypes.string),
  tagFeaturedFeed: PropTypes.shape({
    featured: PropTypes.arrayOf(PropTypes.shape({})),
    top: PropTypes.arrayOf(PropTypes.shape({})),
    tag: PropTypes.object,
    source: PropTypes.string,
    error: PropTypes.bool
  }).isRequired,
  noAds: PropTypes.bool,
  fcWorksCard: PropTypes.shape({
    image: PropTypes.string,
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFeed);
