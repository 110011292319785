import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Card from '../card/card';
import Slug from '../slug/slug';
import PromoBanner from '../promo_banner/promo_banner';
// eslint-disable-next-line no-unused-vars
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import ClientSideWrapper from '../client_side_wrapper/client_side_wrapper';

export default class Scroll extends Component {
  constructor(props) {
    super(props);
    // Is there a better way to do this?
    const navHistory = get(props, 'config.navHistory') || [];
    this.isFC = navHistory[navHistory.length - 1].includes('fastcompany');
    this.isFCHomepage =
      navHistory[navHistory.length - 1] === 'https://www.fastcompany.com/';
  }

  shouldComponentUpdate() {
    return false;
  }

  buildSlugName(tag) {
    const { config } = this.props;
    const tagName = get(tag, '[0].name');
    if (tagName) {
      return tagName;
    }

    // Base on AB test set title
    const featureFlagABTestScrollingModuleParsely = get(
      config,
      'featureFlags.abTestScrollingModuleParsely'
    );
    const abgroup = get(config, 'abgroup');

    if (featureFlagABTestScrollingModuleParsely && this.isFC) {
      if (abgroup === '2') {
        return this.isFCHomepage ? 'Trending' : 'Latest';
      }
    }
    return this.isFC ? 'News' : 'Latest';
  }

  buildSlugLink(tag) {
    const tagData = get(tag, '[0]');
    if (tagData) {
      const tagSlug = get(tagData, 'slug');
      const tagName = get(tagData, 'name');
      if (tagData.taxonomy === 'category') {
        return `/${tagSlug}`;
      }

      if (tagData.taxonomy === 'post_tag') {
        return `/section/${tagSlug}`;
      }

      if (tagData.taxonomy === 'the-latest') {
        return '//www.fastcompany.com/news';
      }

      return tagName;
    }

    return this.isFC ? '//www.fastcompany.com/news' : null;
  }

  render() {
    const {
      config,
      className,
      metadata,
      promoBanner,
      bottomAd,
      shouldRenderDesktop
    } = this.props;
    const featureFlagABTestScrollingModuleParsely = get(
      config,
      'featureFlags.abTestScrollingModuleParsely'
    );
    const abgroup = get(config, 'abgroup');

    if (!metadata) {
      return null;
    }

    if (Array.isArray(metadata.stories) && metadata.stories.length === 0)
      return null;

    const slugName = this.buildSlugName(metadata.tag);
    const slugLink = this.buildSlugLink(metadata.tag);
    const hasPromoBanner = !isEmpty(promoBanner);

    return (
      <div
        className={`column-component control ${className ||
          'card-wrapper--3'} ${bottomAd ? 'scroll-flex' : ''}`}
      >
        {hasPromoBanner ? <PromoBanner {...promoBanner} /> : ''}
        {slugName ? (
          <Slug linkTo={slugLink} slugName={slugName} className="column" />
        ) : (
          ''
        )}
        <div className={bottomAd ? 'scroll-container' : ''}>
          <div
            className={
              hasPromoBanner ? 'scroll-me scroll-me--promoting' : 'scroll-me'
            }
          >
            {metadata.stories.map((item, idx) => (
              <Card
                config={config}
                type="small"
                metadata={item}
                key={item.id}
                cardEvent={() => {
                  trackGAEvent({
                    eventCategory: 'Engagement',
                    eventAction:
                      featureFlagABTestScrollingModuleParsely && abgroup
                        ? `abgroup-${abgroup}:ScrollingModule:Pos-${idx + 1}`
                        : `ScrollingModule:Pos-${idx + 1}`,
                    eventLabel: get(item, 'link')
                  });
                }}
              />
            ))}
          </div>
        </div>

        {bottomAd && (
          <ClientSideWrapper
            showOnlyMobile={!shouldRenderDesktop}
            showOnlyDesktop={shouldRenderDesktop}
          />
        )}
      </div>
    );
  }
}

Scroll.defaultProps = {
  className: '',
  promoBanner: {},
  bottomAd: false,
  shouldRenderDesktop: false
};

Scroll.propTypes = {
  config: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  metadata: PropTypes.shape({}).isRequired,
  promoBanner: PropTypes.shape({}),
  bottomAd: PropTypes.bool,
  shouldRenderDesktop: PropTypes.bool
};
